import React from "react";
import img1 from "../../assets/images/layouts/banner.png";
import img2 from "../../assets/images/icon/icon-01.png";
import img3 from "../../assets/images/layouts/avt-01.png";

function Banner(props) {
  return (
    <section className="banner">
      <div className="shape right"></div>
      <div className="container big">
        <div className="row">
          <div className="col-xl-6 col-md-12">
            <div className="banner__left">
              <div className="block-text">
                <h2 className="heading">
                  {" "}
                  Your solution <br />
                  for global{" "}
                  <span className="s1 arlo_tm_animation_text_word">
                    peer-2-peer
                  </span>{" "}
                  <br />
                  money and crypto transfer
                </h2>
                <p className="desc">
                  Provide liquidity and receive either Ada or a stablecoin of
                  your choice, securely through Ekival.
                </p>

                <a href="https://dapp.ekival.com/" className="action-btn">
                  <span>Launch app</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="banner__right">
              <div className="image">
                <img src={img1} alt="cyfonii" />
              </div>

              <div className="price">
                <div className="icon">
                  <img src={img2} alt="cyfonii" />
                </div>
                <div className="content">
                  <p>Current Bid</p>
                  <h5>ADA</h5>
                </div>
              </div>

              <div className="owner">
                <div className="image">
                  <img src={img3} alt="cyfonii" />
                </div>
                <div className="content">
                  <h5>Mobile Money</h5>
                  <p>transfer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
