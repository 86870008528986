const dataRoadMap = [

	{
		id: 1,
		time: 'September 2024',
		title: 'Testnet & Beta',
		desc: 'Finalise and integrate the smart contracts on Testnet while on-boarding early testers who had provided feedback via the July user Survey',
		class: ''
	},
	{
		id: 2,
		time: 'December 2024',
		title: 'Community Engagement and Token Sale Preparation',
		desc: "Expand social media campaigns on Discord, Facebook, and Twitter to coordinate community events, AMAs, and webinars to explain Ekival's benefits. The whitepaper on token allocation will seek feedback from community members and testers",
		class: 'right'
	},

	{
		id: 3,
		time:'January 2025',
		title: 'Pre-Mainnet Launch and Final Testing',
		desc: 'Finalise the mainnet infrastructure and deployment plan, focusing on security and performance optimizations. Community feedback and legal compliance reviews in preparation for mainnet launch.', class: ''
	},

	{
		id: 4,
		time:'May 2025',
		title: 'Mainnet Launch and Post-Launch Support',
		desc: 'Most users in Africa rely on mobile devices, Liquidity being offered through Ekival can be reserved through an API',
		class: 'right'
	},


]

export default dataRoadMap;