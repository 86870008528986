export const AirDropData = [
    {
        address: "stake1u8hu06gy3hfhcr700p4h2y4f7gzud0lka2njm5ycv0wzmtcpaqj9z",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0ahk3ges48jfac6zngmkh8",
        n_loyalty: 1390526,
        o_pool: "pool1uu65k00dp467qsszmqwl6udwnet3zszgx8n3kmn2aq84sae8ha9",
        o_loyalty: 11835011,
        amount: 1041556281,
        registered: "2024-12-17 12:44:36",
        active_epoch_no: 530,
        holder_detail: "http://cexplorer.io/stake/stake1u8hu06gy3hfhcr700p4h2y4f7gzud0lka2njm5ycv0wzmtcpaqj9z",
        payment_address: "addr1q94h2gzzu37hftykfae2w7mu58q3l99fj6ryvtlxzhjh3wl0cl5sfrwn0s8u77rtw5f2nus9c6lld6489hgfsc7u9khsvaq04d",
        epochs_delegated: 1,
        Ada_delegated: 1041.556281,
        EKI_Rewards: "6,270.75",
        Rate: 0.166098
    },
    {
        address: "stake1ux0p7s5qng8ay6d0q4tvrc24ezsszlxgx994cwg7juap3rspn0shc",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 1451829,
        o_pool: "",
        o_loyalty: 15648387,
        amount: 2024,
        registered: "2024-12-16 19:42:53",
        active_epoch_no: 530,
        holder_detail: "http://cexplorer.io/stake/stake1ux0p7s5qng8ay6d0q4tvrc24ezsszlxgx994cwg7juap3rspn0shc",
        payment_address: "addr1q8k5ulhsjrja0g2tnrxm277unv8lwq5lusq60dc7l2hkfry7rapgpxs06f567p2kc8s4tj9pq97vsv2ttsu3a9e6rz8qh8xt6d",
        epochs_delegated: 1,
        Ada_delegated: 15.648387,
        EKI_Rewards: "1,253.35",
        Rate: 0.012485
    },
    {
        address: "stake1uy6wg6j74etsvme0y7ww5aje5830kxm32l684jp84w0dsrqrq70aw",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 1455817,
        o_pool: "",
        o_loyalty: 21665627,
        amount: 2024,
        registered: "2024-12-16 18:36:25",
        active_epoch_no: 530,
        holder_detail: "http://cexplorer.io/stake/stake1uy6wg6j74etsvme0y7ww5aje5830kxm32l684jp84w0dsrqrq70aw",
        payment_address: "addr1q8lpf6jxv0ppwf2kncftzmzha2cfjr4h3zntmhweas4te2e5u349atjhqehj7fuuafm9ng0zlvdhz4l50tyz02u7mqxqc75n9a",
        epochs_delegated: 1,
        Ada_delegated: 21.665627,
        EKI_Rewards: "1,282.78",
        Rate: 0.016890
    },
    {
        address: "stake1u8hu06gy3hfhcr700p4h2y4f7gzud0lka2njm5ycv0wzmtcpaqj9z",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0ahk3ges48jfac6zngmkh8",
        n_loyalty: 1390526,
        o_pool: "pool1uu65k00dp467qsszmqwl6udwnet3zszgx8n3kmn2aq84sae8ha9",
        o_loyalty: 11835011,
        amount: 1041556281,
        registered: "2024-12-17 12:44:36",
        active_epoch_no: 530,
        holder_detail: "http://cexplorer.io/stake/stake1u8hu06gy3hfhcr700p4h2y4f7gzud0lka2njm5ycv0wzmtcpaqj9z",
        payment_address: "addr1q94h2gzzu37hftykfae2w7mu58q3l99fj6ryvtlxzhjh3wl0cl5sfrwn0s8u77rtw5f2nus9c6lld6489hgfsc7u9khsvaq04d",
        epochs_delegated: 1,
        Ada_delegated: 1041.556281,
        EKI_Rewards: "6,270.75",
        Rate: 0.166098
    },
    {
        address: "stake1ux0p7s5qng8ay6d0q4tvrc24ezsszlxgx994cwg7juap3rspn0shc",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 1451829,
        o_pool: "",
        o_loyalty: 15648387,
        amount: 2024,
        registered: "2024-12-16 19:42:53",
        active_epoch_no: 530,
        holder_detail: "http://cexplorer.io/stake/stake1ux0p7s5qng8ay6d0q4tvrc24ezsszlxgx994cwg7juap3rspn0shc",
        payment_address: "addr1q8k5ulhsjrja0g2tnrxm277unv8lwq5lusq60dc7l2hkfry7rapgpxs06f567p2kc8s4tj9pq97vsv2ttsu3a9e6rz8qh8xt6d",
        epochs_delegated: 1,
        Ada_delegated: 15.648387,
        EKI_Rewards: "1,253.35",
        Rate: 0.012485
    },
    {
        address: "stake1uy6wg6j74etsvme0y7ww5aje5830kxm32l684jp84w0dsrqrq70aw",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 1455817,
        o_pool: "",
        o_loyalty: 21665627,
        amount: 2024,
        registered: "2024-12-16 18:36:25",
        active_epoch_no: 530,
        holder_detail: "http://cexplorer.io/stake/stake1uy6wg6j74etsvme0y7ww5aje5830kxm32l684jp84w0dsrqrq70aw",
        payment_address: "addr1q8lpf6jxv0ppwf2kncftzmzha2cfjr4h3zntmhweas4te2e5u349atjhqehj7fuuafm9ng0zlvdhz4l50tyz02u7mqxqc75n9a",
        epochs_delegated: 1,
        Ada_delegated: 21.665627,
        EKI_Rewards: "1,282.78",
        Rate: 0.016890
    },
    {
        address: "stake1u8dpnfj8em6pgu4mrw9rt9mjrngdtyx4pfk0g37kp6elt7gwuk8gr",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 1545684,
        o_pool: "pool1c86ul4pnqvu7jzag8fjdy6dgrn6pt4ad4vmyq038hyg0wl2kaed",
        o_loyalty: 11919168,
        amount: 293861907,
        registered: "2021-08-23 13:17:36",
        active_epoch_no: 529,
        holder_detail: "http://cexplorer.io/stake/stake1u8dpnfj8em6pgu4mrw9rt9mjrngdtyx4pfk0g37kp6elt7gwuk8gr",
        payment_address: "addr1q8qw6pk4jrzz28lwvalynp7tkp8lg69wxjqjp2m7plxezz76rxny0nh5z3etkxu2xkthy8xs6kgd2znv73ravr4n7huscnu7g3",
        epochs_delegated: 2,
        Ada_delegated: 293.861907,
        EKI_Rewards: "3,790.82",
        Rate: 0.077519
    },
    {
        address: "stake1u9hrm2rfrevtpml9qw2a09pavdr3fz665dxhdqlcmcw6r7qzvmrh2",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 1556159,
        o_pool: "pool1kks6sgxvx7p6fe3hhnne68xzwa9jg8qgy50yt3w3lrelvns7390",
        o_loyalty: 63308165,
        amount: 4694379402,
        registered: "2022-12-13 21:07:58",
        active_epoch_no: 529,
        holder_detail: "http://cexplorer.io/stake/stake1u9hrm2rfrevtpml9qw2a09pavdr3fz665dxhdqlcmcw6r7qzvmrh2",
        payment_address: "addr1q9k3acshrldfad8ags89mlww6euxq2kmy05c2zgj90rtnqnw8k5xj8jckrh72qu4672r6c68zj944g6dw6pl3hsa58uqq6azkv",
        epochs_delegated: 2,
        Ada_delegated: 4694.379402,
        EKI_Rewards: "25,312.40",
        Rate: 0.185458
    },
    {
        address: "stake1u8wpmcav3k2wh5ngajjmd3e3qaq6nesrh4qay654ynch7lgzjpf5l",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 1572296,
        o_pool: "pool1dpu6kslgxlg3ccrwxldl8e6r7ylnq0yalafmucp0yc7k6qegtt0",
        o_loyalty: 1287996,
        amount: 3403591429,
        registered: "2024-11-30 12:28:30",
        active_epoch_no: 529,
        holder_detail: "http://cexplorer.io/stake/stake1u8wpmcav3k2wh5ngajjmd3e3qaq6nesrh4qay654ynch7lgzjpf5l",
        payment_address: "addr1qy69w80d33vtkhd5nn0z6ra8dmzjedmvmanypmshq9x77pkurh36erv5a0fx3m99kmrnzp6p48nq802p6f4f2f830a7sgncf04",
        epochs_delegated: 2,
        Ada_delegated: 3403.591429,
        EKI_Rewards: "18,999.55",
        Rate: 0.179141
    },
    {
        address: "stake1u9xjp6r8j332xl6f05tp6djvpqtpf3jpxtkzkwldw85euqq330936",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 1635952,
        o_pool: "",
        o_loyalty: 354014187,
        amount: 210060180517,
        registered: "2024-12-14 16:34:10",
        active_epoch_no: 529,
        holder_detail: "http://cexplorer.io/stake/stake1u9xjp6r8j332xl6f05tp6djvpqtpf3jpxtkzkwldw85euqq330936",
        payment_address: "addr1qx49n39zwlatphvkfppz8efk3sewkglp6dc98r2zj2305zzdyr5x09rz5dl5jlgkr5myczqkznryzvhv9va76u0fncqqhs8as7",
        epochs_delegated: 2,
        Ada_delegated: 354.014187,
        EKI_Rewards: "4,085.01",
        Rate: 0.086662
    },
    {
        address: "stake1u8nfhmgu0w9g34sf7gtty088pmqrfre6ujq2ylseeyzt6jce9axy8",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 1814831,
        o_pool: "pool1kks6sgxvx7p6fe3hhnne68xzwa9jg8qgy50yt3w3lrelvns7390",
        o_loyalty: 1190080,
        amount: 35838941394,
        registered: "2024-01-31 22:56:18",
        active_epoch_no: 529,
        holder_detail: "http://cexplorer.io/stake/stake1u8nfhmgu0w9g34sf7gtty088pmqrfre6ujq2ylseeyzt6jce9axy8",
        payment_address: "addr1q8cczcm5qnnwr97xu4c72jh4uwmy2cd6pw4kcddhxrh8qc0xn0k3c7u23rtqnuskkg7wwrkqxj8n4eyq5flpnjgyh49s9aqy6s",
        epochs_delegated: 2,
        Ada_delegated: 35838.94139,
        EKI_Rewards: "177,630.88",
        Rate: 0.201761
    },
    {
        address: "stake1u98t0h0s0gz7u9jnt2e397jsdstpypfzcvhm8g5y0hgfp6srz7n9e",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 2053497,
        o_pool: "pool1dpu6kslgxlg3ccrwxldl8e6r7ylnq0yalafmucp0yc7k6qegtt0",
        o_loyalty: 9297839,
        amount: 155145322017,
        registered: "2023-04-15 12:52:13",
        active_epoch_no: 528,
        holder_detail: "http://cexplorer.io/stake/stake1u98t0h0s0gz7u9jnt2e397jsdstpypfzcvhm8g5y0hgfp6srz7n9e",
        payment_address: "addr1q90apk0t6k07fdwmqh04rgj7sgyr9jzxdfjtkkr99ap9pvjwklwlq7s9act9xk4nzta9qmqkzgzj9se0kw3gglwsjr4q8wsrrt",
        epochs_delegated: 3,
        Ada_delegated: 155145.322,
        EKI_Rewards: "762,298.52",
        Rate: 0.203523
    },
    {
        address: "stake1ux62nw59y7qs9xvxfsl26knff6e7lxwcklcaa7rhxwjjd9sgtl36y",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 2260336,
        o_pool: "pool1jgwu8lq3dqddnnx7prymcgcyrqy0fdlghg33he2hmw9t2gma3zd",
        o_loyalty: 1360371,
        amount: 15047782347,
        registered: "2020-12-15 22:23:18",
        active_epoch_no: 528,
        holder_detail: "http://cexplorer.io/stake/stake1ux62nw59y7qs9xvxfsl26knff6e7lxwcklcaa7rhxwjjd9sgtl36y",
        payment_address: "addr1qxkypvdmue74jv0q8z5cxzjwvfuaaqsj6vteejq35xh2w7d54xag2fupq2vcvnp744dxjn4na7va3dl3mmu8wva9y6tqz8ll8q",
        epochs_delegated: 3,
        Ada_delegated: 15047.78235,
        EKI_Rewards: "77,124.53",
        Rate: 0.195110
    },
    {
        address: "stake1ux07khqwphy3jre4jnwp847v6wtaw4qz869rxl6ekavkqjqvt2rfs",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 2309396,
        o_pool: "pool17xh922cr9skc0fqk7sr8at6xx6eluh5qyv9p7ca2x0mtc3st0mz",
        o_loyalty: 24268530,
        amount: 16491180553,
        registered: "2022-01-19 15:49:08",
        active_epoch_no: 528,
        holder_detail: "http://cexplorer.io/stake/stake1ux07khqwphy3jre4jnwp847v6wtaw4qz869rxl6ekavkqjqvt2rfs",
        payment_address: "addr1q85qy76ecz4723qt2k2fvggfu4wlkrgculujvfxr5f4j6jvladwqurwfry8nt9xuz0tue5uh6a2qy052xdl4nd6evpyqvkr7av",
        epochs_delegated: 3,
        Ada_delegated: 16491.18055,
        EKI_Rewards: "84,183.74",
        Rate: 0.195895
    },
    {
        address: "stake1u85xqhw7g6njma73uuc423zyqkd7pwx9pc2lcv0rx3req6cczxnad",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 2392577,
        o_pool: "pool1dqhaw0h2f4ehpdhh57quszcj22qhkglhg6yvaf39w3clxdl2kpy",
        o_loyalty: 61667559,
        amount: 3108763931,
        registered: "2021-12-20 05:55:06",
        active_epoch_no: 528,
        holder_detail: "http://cexplorer.io/stake/stake1u85xqhw7g6njma73uuc423zyqkd7pwx9pc2lcv0rx3req6cczxnad",
        payment_address: "addr1qx3u8nxscrt5nylvudq6y6css3zhc5e6090dl4eka5xvqn8gvpwau3489hmaree324zygpvmuzuv2rs4lsc7xdz8jp4s7a4v4x",
        epochs_delegated: 3,
        Ada_delegated: 3108.763931,
        EKI_Rewards: "18,734.46",
        Rate: 0.165938
    },
    {
        address: "stake1ux4q5fssr2xmtcp5w2wa4mph378en3jvf6sr7zu64l9nlwg4jygsq",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 2739287,
        o_pool: "pool1j742gcsul7u8c05rsqmkgpkvj5qadj0pew69nctu6crqy9wjjn4",
        o_loyalty: 63245292,
        amount: 10012734176,
        registered: "2022-11-30 21:57:03",
        active_epoch_no: 527,
        holder_detail: "http://cexplorer.io/stake/stake1ux4q5fssr2xmtcp5w2wa4mph378en3jvf6sr7zu64l9nlwg4jygsq",
        payment_address: "addr1q88zfe3m6mts76cstpp2uzzs8ejhvdhyqhnev0vk0d8vg5a2pgnpqx5dkhsrgu5amtkr0ru0n8rycn4q8u9e4t7t87us6l3knp",
        epochs_delegated: 4,
        Ada_delegated: 10012.73418,
        EKI_Rewards: "53,676.47",
        Rate: 0.186539
    },
    {
        address: "stake1u8e280caeh78me0jucp2fsg99ag23f8al6u3vj6wfwa52jcn0ymrm",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 2758265,
        o_pool: "",
        o_loyalty: 907888540,
        amount: 2024,
        registered: "2024-12-01 16:48:57",
        active_epoch_no: 527,
        holder_detail: "http://cexplorer.io/stake/stake1u8e280caeh78me0jucp2fsg99ag23f8al6u3vj6wfwa52jcn0ymrm",
        payment_address: "addr1q8tk46xsnhdfyhts64r8quz6c25my32l8q8y8yxmdh0z4nlj5wl3mn0u0hjl9esz5nqs2t6s4zj0ml4eze95ujamg49spregkj",
        epochs_delegated: 4,
        Ada_delegated: 907.88854,
        EKI_Rewards: "9,147.47",
        Rate: 0.099250
    },
    {
        address: "stake1uyuq4nmn5knza6pvmgjd8wx3d38p0kadlm5dhe96nunmwagxrmvel",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 3187869,
        o_pool: "",
        o_loyalty: 15487063,
        amount: 2024,
        registered: "2024-11-26 17:28:53",
        active_epoch_no: 526,
        holder_detail: "http://cexplorer.io/stake/stake1uyuq4nmn5knza6pvmgjd8wx3d38p0kadlm5dhe96nunmwagxrmvel",
        payment_address: "addr1q9m7ltsuz8fzcfmjs93f3gw4mqeprgyx59a27plrugw8p7ecpt8h8fdx9m5zek3y6wudzmzwzld6mlhgm0jt48e8ka6s375y2u",
        epochs_delegated: 5,
        Ada_delegated: 15.487063,
        EKI_Rewards: "5,959.83",
        Rate: 0.002599
    },
    {
        address: "stake1uywtmktr28m4ly92dlrdt59xavvh4lw6l320s77xaq5vfwcvcdncw",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 3953324,
        o_pool: "pool1a2gt2mvuf5zvtqlvw2xgks2efze3p4r985ft62q64lua7gx7lal",
        o_loyalty: 83241477,
        amount: 22172467,
        registered: "2024-11-17 20:51:18",
        active_epoch_no: 524,
        holder_detail: "http://cexplorer.io/stake/stake1uywtmktr28m4ly92dlrdt59xavvh4lw6l320s77xaq5vfwcvcdncw",
        payment_address: "addr1q9x9wjqk79hwx5hesuvaj32cgs39xtjnhdlzsez0cmpx4gquhhvkx50ht7g25m7x6hg2d6ce0t7a4lz5lpaud6pgcjase9pl5r",
        epochs_delegated: 7,
        Ada_delegated: 22.172467,
        EKI_Rewards: "8,346.16",
        Rate: 0.002657
    },
    {
        address: "stake1u9dd2qt2jvdmxvrz8tfc6wtdlrkxqky9enljgnu7y5ukcxqq4khay",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 4079619,
        o_pool: "pool1dmnyhw9uthknzcq4q6pwdc4vtfxz5zzrvd9eg432u60lzl959tw",
        o_loyalty: 83886,
        amount: 210060180517,
        registered: "2021-09-17 08:44:05",
        active_epoch_no: 524,
        holder_detail: "http://cexplorer.io/stake/stake1u9dd2qt2jvdmxvrz8tfc6wtdlrkxqky9enljgnu7y5ukcxqq4khay",
        payment_address: "addr1q8k9zyjf9xk7yneknymxnqfkcflyn3fc0t42u3qrdqu5cm6665qk4ycmkvcxywkn35ukm78vvpvgtn8ly38euffedsvq26wd20",
        epochs_delegated: 7,
        Ada_delegated: 210060.1805,
        EKI_Rewards: "1,035,577.47",
        Rate: 0.202844
    },
    {
        address: "stake1uxfa85z4ln5pky4a0v0tls75csk9mf5hw5txcltx6r29wkgxesujv",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 4308253,
        o_pool: "pool1ea34czsr90yc63fmv4gj4tetp7ds7c4jhpj6vqg4yw40wvygdl3",
        o_loyalty: 87736622,
        amount: 45772330,
        registered: "2022-02-02 06:16:40",
        active_epoch_no: 523,
        holder_detail: "http://cexplorer.io/stake/stake1uxfa85z4ln5pky4a0v0tls75csk9mf5hw5txcltx6r29wkgxesujv",
        payment_address: "addr1q9zm0mtp2s9s8mm000gguqzuxqgc7nf6tryls4n43yntnmvn60g9tl8grvft67c7hlpaf3pvtknfwagkd37kd5x52avsuyckvv",
        epochs_delegated: 8,
        Ada_delegated: 45.77233,
        EKI_Rewards: "9,638.39",
        Rate: 0.004749
    },
    {
        address: "stake1uy0dktej3mu474frxa7equc5m8w86kxd9tqktz6zdxegqzs9qd2py",
        n_pool: "pool1jqssxka8hu94aj6f4w3s8l5m6juqmx0hk3ges48jfac6zngmkh8",
        n_loyalty: 4383703,
        o_pool: "pool1h6q8jj55dn6727yydlypu0rz4sflf2enxhs0thqydmddgu3shl5",
        o_loyalty: 29920821,
        amount: 325503252693,
        registered: "2023-12-02 13:57:58",
        active_epoch_no: 523,
        holder_detail: "http://cexplorer.io/stake/stake1uy0dktej3mu474frxa7equc5m8w86kxd9tqktz6zdxegqzs9qd2py",
        payment_address: "addr1qyc2e0f4l7hz0a0ekzd69u4xf0mygxhw3jhfjee9njpnu2q7mvhn9rheta2jxdmajpe3fkwu04vv62kpvk95y6djsq9qpfm2a7",
        epochs_delegated: 8,
        Ada_delegated: 325503.2527,
        EKI_Rewards: "1,601,350.86",
        Rate: 0.203268
    },
    
];



