const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },
  {
    id: 2,
    name: "RoadMap",
    links: "/road-map",
  },

  // {
  //   id: 4,
  //   name: "About",
  //   links: "/about",
  // },

  // {
  //   id: 5,
  //   name: "Explore",
  //   links: "#",
  //   namesub: [
  //     {
  //       id: 1,
  //       sub: "Visions & Mission",
  //       links: "/visions-mission",
  //     },
  //     {
  //       id: 2,
  //       sub: "Help Center",
  //       links: "/help-center",
  //     },

  //     {
  //       id: 3,
  //       sub: "Advisors & Backers",
  //       links: "/advisors-backers",
  //     },
  //   ],
  // },
  {
    id: 6,
    name: "Team",
    links: "/team",
  },
  {
    id: 7,
    name: "Contact",
    links: "/contact",
  },
  {
    id: 7,
    name: "FAQ",
    links: "/faq",
  },
   {
    id: 7,
    name: "AirDrop",
    links: "/air-drop",
  },
];

export default menus;
