export const fr = {
  // Navigation
  Home: "Accueil",
  RoadMap: "Feuille de route",
  Team: "Équipe",
  Contact: "Contact",
  AirDrop: "AirDrop",

  // AirDrop Page
  airdropTitle: "AirDrop",
  airdropSubtitle: "Vous pouvez vérifier votre allocation en EKI token",
  airdropHelpTitle: "Comment ça marche ?",
  airdropHelpStep1Title: "Délégation",
  airdropHelpStep1Text: "Déléguez vos ADA au Goma Stake Pool pour être éligible aux récompenses EKI",
  airdropHelpStep2Title: "Vérification",
  airdropHelpStep2Text: "Entrez votre adresse de staking (commence par stake1) ou votre adresse de paiement (commence par addr1)",
  airdropHelpStep3Title: "Récompenses",
  airdropHelpStep3Text: "Vos récompenses sont calculées en fonction de votre fidélité et du montant délégué",
  airdropHelpTipTitle: "Conseil",
  airdropHelpTipText: "Plus vous déléguez longtemps, plus vos récompenses seront importantes",
  airdropInputLabel: "Entrez votre adresse si vous avez déléguez au Goma Stake Pool",
  airdropSearchButton: "Rechercher",
  airdropNoAddress: "Veuillez entrer une adresse",
  airdropNoResult: "Aucune allocation trouvée pour cette adresse",
  airdropResultFound: "Résultat trouvé",
  airdropSearching: "Recherche en cours...",
  airdropError: "Une erreur s'est produite lors de la recherche. Veuillez réessayer.",
  airdropAddressLabel: "Adresse",
  airdropPoolLabel: "Pool",
  airdropAllocationLabel: "Allocation",
  airdropLoyaltyLabel: "Fidélité",
  airdropOldPoolLabel: "Pool Précédent",
  airdropOldLoyaltyLabel: "Fidélité Précédente",
  airdropRegisteredLabel: "Inscrit le",
  airdropActiveEpochLabel: "Époque Active",
  airdropEpochsDelegatedLabel: "Époques Déléguées",
  airdropAdaDelegatedLabel: "ADA Délégué",
  airdropRateLabel: "Taux",
  airdropViewOnExplorerLabel: "Voir sur l'Explorateur",
  airdropPaymentAddressLabel: "Adresse de Paiement",

  // Header
  welcomeMessage: "Bienvenue sur Ekival",
  learnMore: "En savoir plus",
  launchApp: "Lancer l'application",

  // About
  aboutTitle: "À propos de nous",
  aboutDescription: "Nous sommes une entreprise innovante spécialisée dans...",
  ourMission: "Notre Mission",
  ourVision: "Notre Vision",

  // Team
  teamTitle: "Notre Équipe Exceptionnelle",
  teamSubheading: "Membres de l'Équipe",

  // Services
  servicesTitle: "Nos Services",
  serviceDescription: "Découvrez nos services exceptionnels",

  // Portfolio
  portfolioTitle: "Notre Portfolio",
  viewProject: "Voir le projet",

  // Contact
  contactTitle: "Contactez-nous",
  name: "Nom",
  email: "Email",
  message: "Message",
  send: "Envoyer",

  // Contact Page
  contactSubheading: "Contactez-nous",
  contactHeading1: "Commençons à travailler",
  contactHeading2: "ensemble",
  contactInfoTitle: "Informations de contact",
  contactAddress: "Ekival Remit Ltd. 2020 Lanthier Dr #9, Suite #236 Ottawa, ON K4A 3V4",
  contactPhone: "+1-866-966-6282",
  contactEmail: "support@ekival.com",
  contactImageAlt: "Image de contact",
  
  // Contact Form
  contactFormName: "Votre nom",
  contactFormEmail: "Votre email",
  contactFormPhone: "Votre téléphone",
  contactFormSubject: "Sujet",
  contactFormMessage: "Message",
  contactFormSubmit: "Envoyer maintenant",
  contactFormSubmitting: "Envoi en cours...",
  contactFormSelectDefault: "Sélectionnez",
  contactFormSelectSupport: "Support technique",
  contactFormSelectBusiness: "Opportunités commerciales",
  contactFormSelectOther: "Autre",
  contactFormNamePlaceholder: "Entrez votre nom complet",
  contactFormEmailPlaceholder: "Entrez votre adresse email",
  contactFormPhonePlaceholder: "Entrez votre numéro de téléphone",
  contactFormMessagePlaceholder: "Écrivez votre message ici...",
  contactFormSuccess: "Votre message a été envoyé avec succès. Nous vous répondrons dans les plus brefs délais.",
  contactFormError: "Une erreur s'est produite lors de l'envoi du message. Veuillez réessayer.",
  contactErrorName: "Le nom est requis",
  contactErrorEmail: "L'email est requis",
  contactErrorEmailInvalid: "Veuillez entrer une adresse email valide",
  contactErrorSubject: "Le sujet est requis",
  contactErrorMessage: "Le message est requis",
  
  // Map
  contactMapTitle: "Notre emplacement sur la carte",

  // Footer
  allRightsReserved: "Tous droits réservés",
  privacyPolicy: "Politique de confidentialité",
  termsOfService: "Conditions d'utilisation",
// FAQ Section
faqSubheading: "FAQs",
faqHeading: "Questions Fréquemment Posées",
faqDescription: "Voici une liste des questions fréquemment posées et des réponses de nos partenaires et artistes 3D. Veuillez consulter cette FAQ avant de nous contacter.",
faqImageAlt: "Illustration FAQ",

// FAQ Tabs
faqTabCrypto: "Cryptomonnaie",
faqTabNFT: "Jeton NFT",
faqTabCollection: "Collection",
faqTabTrading: "Trading Crypto",

// FAQ Items
faq1Title: "Qu'est-ce que la cryptomonnaie ?",
faq1Text: "La cryptomonnaie est une forme de monnaie numérique ou virtuelle qui utilise la cryptographie pour la sécurité...",
faq2Title: "Comment commencer à trader ?",
faq2Text: "Pour commencer à trader, vous devez créer un compte sur une plateforme d'échange de cryptomonnaies...",

  // FAQ Page
  faqPageTitle: "FAQ",
  faqSectionSubheading: "FAQs",
  faqSectionHeading: "Questions Fréquemment Posées pour Ekival",

  // FAQ Questions and Answers
  faqQ1: "Qu'est-ce qu'Ekival ?",
  faqA1: "Ekival est une plateforme non-custodiale basée sur la blockchain, conçue pour des transferts d'argent pair à pair rapides, sécurisés et abordables. Elle permet aux utilisateurs d'envoyer et de recevoir des fonds globalement en utilisant des monnaies fiduciaires ou des cryptomonnaies, tout en garantissant qu'ils conservent le contrôle total de leurs fonds à tout moment.",

  faqQ2: "Comment fonctionne Ekival ?",
  faqA2: "Ekival utilise la technologie blockchain pour connecter directement les expéditeurs et les destinataires. Les fonds sont sécurisés dans des contrats intelligents d'entiercement, assurant confiance et transparence. En tant que plateforme non-custodiale, Ekival ne détient pas vos fonds ni vos clés privées, vous donnant un contrôle complet sur votre argent.",

  faqQ3: "Que signifie \"non-custodial\" ?",
  faqA3: "Être non-custodial signifie qu'Ekival ne prend pas possession ni ne contrôle vos fonds ou vos clés privées. Les transactions sont gérées de manière sécurisée via des contrats intelligents sur la blockchain, vous permettant de maintenir la garde complète de vos actifs tout au long du processus.",

  faqQ4: "Quels sont les avantages d'utiliser Ekival ?",
  faqA4: "Sécurité Non-Custodiale : Vos fonds et clés privées sont toujours sous votre contrôle. Frais Réduits : Coûts de transaction minimaux comparés aux services de transfert traditionnels. Rapidité : Traitement quasi instantané des transferts. Transparence : Les transactions sont sécurisées et enregistrées sur une blockchain. Portée Mondiale : Support pour les transferts transfrontaliers en monnaies fiduciaires et cryptomonnaies. Incitations : Les utilisateurs peuvent gagner des jetons EKI pour leur participation.",

  faqQ5: "Qu'est-ce que le jeton EKI ?",
  faqA5: "Le jeton EKI est le jeton utilitaire natif de la plateforme Ekival. Il peut être utilisé pour : Payer les frais de transaction (avec des réductions). Participer aux programmes de staking et de liquidité. Voter sur les décisions de gouvernance au sein de l'écosystème Ekival. Recevoir des récompenses et des incitations pour l'utilisation de la plateforme.",

  faqQ6: "Comment gagner des jetons EKI ?",
  faqA6: "Vous pouvez gagner des jetons EKI en : Participant aux campagnes d'airdrop en stakant de l'ADA dans le Goma Stake Pool. Utilisant la plateforme Ekival et en mintant vos récompenses. Participant au programme de récompenses pour les utilisateurs actifs et les délégateurs. Parrainages et promotions.",

  faqQ7: "Qu'est-ce que le Goma Stake Pool et quel est son lien avec Ekival ?",
  faqA7: "Le Goma Stake Pool est un pool de staking Cardano et un partenaire clé d'Ekival. Les délégateurs qui stakent de l'ADA dans le Goma Stake Pool avant la date limite (par exemple, le 31 décembre) sont éligibles aux récompenses en jetons EKI. Les récompenses sont basées sur le montant et la durée de la délégation d'ADA.",

  faqQ8: "Comment envoyer de l'argent avec Ekival ?",
  faqA8: "Connectez votre portefeuille : Sur la plateforme Ekival, la signature d'une transaction de jeton JWT vous permettra d'ouvrir une session sécurisée pour les communications et les mises à jour de profil. Approvisionnez votre portefeuille : Ajoutez des fonds avec Ada ou votre compte mobile money le cas échéant. Initiez une offre : Accédez à votre tableau de bord, entrez le montant et les détails de votre offre de liquidité (Achat ou Vente). Acceptez une commande : Acceptez les offres des utilisateurs Ekival. Suivez le statut : Surveillez le transfert via le tableau de bord Ekival jusqu'à la confirmation du destinataire. Complétez la transaction : Le contrat intelligent allouera les fonds moins les frais.",

  faqQ9: "Quelles devises sont prises en charge sur Ekival ?",
  faqA9: "Ekival prend en charge à la fois les monnaies fiduciaires (par exemple, CDF, monnaies locales via mobile money) et les cryptomonnaies (par exemple, ADA, EKI, Djed). D'autres devises peuvent être ajoutées en fonction de la demande.",

  faqQ10: "Quels sont les frais facturés par Ekival ?",
  faqA10: "Ekival facture des frais de transaction modiques allant de 0,5% à 1,5%, selon la méthode de transfert. Des réductions sont disponibles pour les utilisateurs qui paient les frais avec des jetons EKI.",

  faqQ11: "Comment Ekival assure-t-il la sécurité ?",
  faqA11: "Ekival privilégie la sécurité à travers : Design Non-Custodial : Les utilisateurs conservent le contrôle total de leurs fonds. Technologie Blockchain : Les transactions sont immuables et transparentes. Contrats Intelligents : Les fonds sont conservés en dépôt fiduciaire jusqu'à la confirmation des deux parties. Chiffrement : Les données des utilisateurs et les fonds sont sécurisés avec des protocoles de chiffrement avancés. Conformité Réglementaire : Ekival respecte les réglementations AML (Anti-Blanchiment d'Argent) et KYC (Connaissance du Client).",

  faqQ12: "Comment staker de l'ADA pour être éligible aux récompenses en jetons EKI ?",
  faqA12: "Ouvrez votre portefeuille compatible Cardano (par exemple, Yoroi, Daedalus ou Nami). Recherchez le Goma Stake Pool. Déléguez votre ADA au pool avant la date limite. Maintenez votre délégation pour des récompenses maximales.",

  faqQ13: "Puis-je utiliser Ekival sans cryptomonnaie ?",
  faqA13: "Ekival prend en charge les transferts en monnaie fiduciaire via mobile money, les retraits en espèces et les comptes bancaires. Vous aurez cependant besoin d'un montant minimum d'Ada pour confirmer la transaction sur la blockchain car Ekival est non-custodial. Des options uniquement en cryptomonnaie sont disponibles pour les utilisateurs qui préfèrent les transactions basées sur la blockchain.",

  faqQ14: "Que se passe-t-il si une transaction échoue ?",
  faqA14: "Si une transaction échoue : Les fonds conservés en dépôt fiduciaire sont toujours en sécurité et vous ne serez pas facturé car le portefeuille ne soumettra pas la transaction. En tant que plateforme non-custodiale, Ekival garantit que vos fonds restent sécurisés tout au long du processus. L'équipe de support d'Ekival est disponible 24h/24 et 7j/7 pour vous aider avec tout problème comme l'extension des délais.",

  faqQ15: "Comment contacter le support client ?",
  faqA15: "Vous pouvez joindre le support client d'Ekival via : Email : support@ekival.com Chat en direct : Disponible sur le site web et l'application mobile Ekival. Téléphone : 1-866-966-6282.",

  faqQ16: "En quoi Ekival est-il différent des services de transfert d'argent traditionnels ?",
  faqA16: "Ekival offre plusieurs avantages par rapport aux services traditionnels : Sécurité Non-Custodiale : Vous gardez le contrôle de vos fonds tout au long du processus de transaction. Frais Réduits : Coûts minimaux comparés aux services de transfert d'argent traditionnels. Transparence Basée sur la Blockchain : Assure la confiance et la responsabilité. Incitations : Gagnez des jetons EKI pour la participation et le staking.",

  faqQ17: "Ekival est-il disponible dans le monde entier ?",
  faqA17: "Oui, Ekival est conçu pour prendre en charge les transactions mondiales. La disponibilité peut varier selon les réglementations locales et les intégrations des partenaires. Les résidents américains doivent consulter nos conditions d'utilisation.",

  faqQ18: "Comment Ekival assure-t-il la conformité aux réglementations ?",
  faqA18: "Ekival Remit Ltd. est enregistrée comme entreprise de services monétaires (MSB) au Canada et respecte les exigences AML et KYC. Son design non-custodial réduit également les charges réglementaires tout en assurant la sécurité des utilisateurs.",

  faqQ19: "Les entreprises peuvent-elles utiliser Ekival ?",
  faqA19: "Absolument ! Ekival propose des solutions pour les entreprises, notamment : Paiements transfrontaliers de salaires et de fournisseurs. Frais réduits pour les transactions en masse. Intégrations personnalisées pour les transferts à haut volume. Transfert Fiat-to-Crypto pour les besoins d'import-export.",

  faqQ20: "Quel est le montant minimum de transfert ?",
  faqA20: "Le montant minimum de transfert varie selon la devise et la méthode. Pour les transferts en cryptomonnaie, il est généralement équivalent à 10 USD. Pour les transferts en monnaie fiduciaire, les minimums peuvent varier selon la région et la méthode de transfert.",

  // Common
  readMore: "Lire plus",
  sendMessage: "Envoyer un message",
  subscribe: "S'abonner",
  newsletter: "Newsletter",
  followUs: "Suivez-nous",
  currentTime: "L'heure locale actuelle est:",
  loading: "Chargement...",
  error: "Erreur",
  success: "Succès",

  // Banner
  bannerTitle1: "Votre solution",
  bannerTitle2: "pour les transferts",
  bannerTitle3: "pair-à-pair",
  bannerTitle4: "d'argent et de crypto mondiaux",
  bannerDescription: "Fournissez de la liquidité et recevez soit de l'Ada soit une stablecoin de votre choix, en toute sécurité via Ekival.",
  currentBid: "Offre actuelle",
  mobileMoneyTitle: "Mobile Money",
  mobileMoneySubtitle: "transfert",
  mobileMoneyAlt: "mobile money",

  // Roadmap
  roadmapSubheading: "Feuille de route",
  roadmapHeading: "Le parcours d'EKIVAL",
  roadmapLineAlt: "ligne de temps",
  viewFullRoadmap: "Voir la feuille de route complète",

  // Roadmap Timeline
  roadmapTime1: "Septembre 2024",
  roadmapTitle1: "Testnet & Beta",
  roadmapDesc1: "Finalisation et intégration des contrats intelligents sur le Testnet tout en intégrant les premiers testeurs qui ont fourni des retours via l'enquête utilisateur de juillet",

  roadmapTime2: "Décembre 2024",
  roadmapTitle2: "Engagement communautaire et préparation de la vente de tokens",
  roadmapDesc2: "Expansion des campagnes sur les réseaux sociaux Discord, Facebook et Twitter pour coordonner les événements communautaires, les AMA et les webinaires expliquant les avantages d'Ekival. Le livre blanc sur l'allocation des tokens sollicitera les retours des membres de la communauté et des testeurs",

  roadmapTime3: "Janvier 2025",
  roadmapTitle3: "Pré-lancement Mainnet et tests finaux",
  roadmapDesc3: "Finalisation de l'infrastructure mainnet et du plan de déploiement, en mettant l'accent sur les optimisations de sécurité et de performance. Retours de la communauté et examens de conformité juridique en préparation du lancement mainnet",

  roadmapTime4: "Mai 2025",
  roadmapTitle4: "Lancement Mainnet et support post-lancement",
  roadmapDesc4: "La plupart des utilisateurs en Afrique utilisent des appareils mobiles, la liquidité offerte via Ekival peut être réservée via une API",

  // Team
  teamSectionSubheading: "Membres de l'équipe",
  teamSectionHeading: "Notre incroyable équipe",
  teamMemberImageAlt: "Photo de {{name}}",
  
  // Team Members
  teamMember1Name: "Frédéric Samvura",
  teamMember1Position: "Développeur commercial",
  teamMember2Name: "M. Ali Modiri",
  teamMember2Position: "Développeur Smart Contract",
  teamMember3Name: "Ali Khaleghi Far",
  teamMember3Position: "Développeur Front-End",

  // Social Media
  socialFacebook: "Suivez-nous sur Facebook",
  socialTwitter: "Suivez-nous sur Twitter",
  socialTelegram: "Rejoignez-nous sur Telegram",

  // Language
  switchLanguage: "EN",
};
