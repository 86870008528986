import React from 'react';
import Banner from '../components/banner/Banner';
import Footer from '../components/footer/Footer';



function Home(props) {

    return (
        <div className="home-1 wrapper">
            
            <Banner />
            <Footer />
        </div>
        

    );
}

export default Home;