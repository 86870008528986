import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AirDropData } from '../components/air-drop';
import Footer from '../components/footer/Footer';
import { motion, AnimatePresence } from 'framer-motion';
import '../styles/airdrop.scss';

const AirDrop = () => {
  const { t } = useTranslation();
  const [address, setAddress] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [adaPrice, setAdaPrice] = useState(null);
  const [priceChange, setPriceChange] = useState(null);
  const [adaPriceEur, setAdaPriceEur] = useState(null);
  const [isLoadingPrice, setIsLoadingPrice] = useState(true);
  const [priceError, setPriceError] = useState(null);

  const handleSearch = async () => {
    setError('');
    setSearchResult(null);

    if (!address.trim()) {
      setError(t('airdropNoAddress'));
      return;
    }

    setIsLoading(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 800));
      const result = AirDropData.find(item => 
        item.address.toLowerCase() === address.toLowerCase() ||
        item.payment_address.toLowerCase() === address.toLowerCase()
      );
      
      if (result) {
        setSearchResult(result);
      } else {
        setError(t('airdropNoResult'));
      }
    } catch (err) {
      setError(t('airdropError'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  useEffect(() => {
    const fetchAdaPrice = async () => {
      setIsLoadingPrice(true);
      setPriceError(null);
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=cardano&vs_currencies=usd,eur&include_24hr_change=true');
        if (!response.ok) throw new Error('Failed to fetch price data');
        
        const data = await response.json();
        setAdaPrice(data.cardano.usd);
        setAdaPriceEur(data.cardano.eur);
        setPriceChange(data.cardano.usd_24h_change);
      } catch (err) {
        console.error('Error fetching ADA price:', err);
        setPriceError('Unable to fetch current price');
      } finally {
        setIsLoadingPrice(false);
      }
    };

    fetchAdaPrice();
    const interval = setInterval(fetchAdaPrice, 300000); // 5 minutes
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="wrapper">
      <section className="airdrop">
        <div className="shape"></div>
        <motion.div 
          className="ada-price-banner"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {isLoadingPrice ? (
            <div className="price-loading">
              <div className="loading-spinner"></div>
              <span>Fetching prices...</span>
            </div>
          ) : priceError ? (
            <div className="price-error">
              <i className="fas fa-exclamation-circle"></i>
              <span>{priceError}</span>
            </div>
          ) : (
            <div className="price-container">
              <div className="price-row">
                <span className="price-label">ADA/USD:</span>
                <span className="price-value">${adaPrice?.toFixed(2)}</span>
                {priceChange && (
                  <motion.span 
                    className={`price-change ${priceChange >= 0 ? 'positive' : 'negative'}`}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    <i className={`fas fa-arrow-${priceChange >= 0 ? 'up' : 'down'}`}></i>
                    {Math.abs(priceChange).toFixed(2)}%
                  </motion.span>
                )}
              </div>
              {adaPriceEur && (
                <div className="price-row">
                  <span className="price-label">ADA/EUR:</span>
                  <span className="price-value">€{adaPriceEur.toFixed(2)}</span>
                </div>
              )}
            </div>
          )}
        </motion.div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="block-text center">
                <motion.h6
                  className="sub-heading"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.2 }}
                >
                  <span>{t('airdropTitle')}</span>
                </motion.h6>
                <motion.h3
                  className="heading pd"
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    delay: 0.4,
                    ease: [0.4, 0, 0.2, 1]
                  }}
                >
                  {t('airdropSubtitle')}
                </motion.h3>

                <div className="help-section">
                  <h4 className="help-title">{t('airdropHelpTitle')}</h4>
                  <div className="help-steps">
                    <div className="help-step">
                      <div className="step-number">1</div>
                      <div className="step-content">
                        <h5>{t('airdropHelpStep1Title')}</h5>
                        <p>{t('airdropHelpStep1Text')}</p>
                      </div>
                    </div>
                    <div className="help-step">
                      <div className="step-number">2</div>
                      <div className="step-content">
                        <h5>{t('airdropHelpStep2Title')}</h5>
                        <p>{t('airdropHelpStep2Text')}</p>
                      </div>
                    </div>
                    <div className="help-step">
                      <div className="step-number">3</div>
                      <div className="step-content">
                        <h5>{t('airdropHelpStep3Title')}</h5>
                        <p>{t('airdropHelpStep3Text')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="help-tip">
                    <div className="tip-icon">💡</div>
                    <div className="tip-content">
                      <h5>{t('airdropHelpTipTitle')}</h5>
                      <p>{t('airdropHelpTipText')}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-box">
                <label>{t('airdropInputLabel')}</label>
                <input 
                  type="text" 
                  className="form-control"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="stake1... or addr1..."
                  aria-label={t('airdropInputLabel')}
                  disabled={isLoading}
                />
                <button 
                  className={`search-button ${isLoading ? 'loading' : ''}`}
                  onClick={handleSearch}
                  disabled={isLoading}
                >
                  {isLoading ? t('airdropSearching') : t('airdropSearchButton')}
                </button>

                {error && (
                  <div className="error-message">
                    {error}
                  </div>
                )}

                <AnimatePresence>
                  {searchResult && (
                    <motion.div 
                      className="result-card"
                      initial={{ opacity: 0, scale: 0.95 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.95 }}
                      transition={{ duration: 0.3 }}
                    >
                      <h4 className="success-header">
                        {t('airdropResultFound')}
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="currentColor"/>
                        </svg>
                      </h4>
                      <div className="result-content">
                        <div className="allocation-box">
                          <div className="label">{t('airdropAllocationLabel')}</div>
                          <div className="amount">
                            <span>{searchResult.EKI_Rewards}</span>
                            <span className="currency">EKI</span>
                          </div>
                        </div>
                        <div className="info-row">
                          <strong>{t('airdropLoyaltyLabel')}:</strong>
                          <span>{searchResult.n_loyalty}</span>
                        </div>
                        <div className="info-row">
                          <strong>{t('airdropPoolLabel')}:</strong>
                          <span>{searchResult.n_pool}</span>
                        </div>
                        <div className="info-row">
                          <strong>{t('airdropOldLoyaltyLabel')}:</strong>
                          <span>{searchResult.o_loyalty}</span>
                        </div>
                        <div className="info-row">
                          <strong>{t('airdropOldPoolLabel')}:</strong>
                          <span>{searchResult.o_pool || '-'}</span>
                        </div>
                        <div className="info-row">
                          <strong>{t('airdropRegisteredLabel')}:</strong>
                          <span>{searchResult.registered}</span>
                        </div>
                        <div className="info-row">
                          <strong>{t('airdropActiveEpochLabel')}:</strong>
                          <span>{searchResult.active_epoch_no}</span>
                        </div>
                        <div className="info-row">
                          <strong>{t('airdropEpochsDelegatedLabel')}:</strong>
                          <span>{searchResult.epochs_delegated}</span>
                        </div>
                        <div className="info-row">
                          <strong>{t('airdropAdaDelegatedLabel')}:</strong>
                          <span>{searchResult.Ada_delegated} ADA</span>
                        </div>
                        <div className="info-row">
                          <strong>{t('airdropRateLabel')}:</strong>
                          <span>{searchResult.Rate}</span>
                        </div>
                        <div className="info-row full-width clickable" onClick={() => window.open(searchResult.holder_detail, '_blank')}>
                          <strong>{t('airdropViewOnExplorerLabel')}:</strong>
                          <span>View on cexplorer.io</span>
                        </div>
                        <div className="info-row full-width">
                          <strong>{t('airdropPaymentAddressLabel')}:</strong>
                          <span>{searchResult.payment_address}</span>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AirDrop;