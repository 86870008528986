import React, { useState } from "react";
import PageTitle from "../components/pagetitle/PageTitle";
import Faq3 from "../components/faqs/Faq3";
import dataFaqs from "../assets/fake-data/data-faq";
import Footer from "../components/footer/Footer";
import img from "../assets/images/layouts/touch.png";
import { useTranslation } from "../hooks/useTranslation";

function Contact(props) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = t('contactErrorName');
    if (!formData.email.trim()) {
      newErrors.email = t('contactErrorEmail');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = t('contactErrorEmailInvalid');
    }
    if (!formData.subject) newErrors.subject = t('contactErrorSubject');
    if (!formData.message.trim()) newErrors.message = t('contactErrorMessage');

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      // TODO: Replace with actual API endpoint
      await new Promise(resolve => setTimeout(resolve, 1000));
      setSubmitStatus('success');
      setFormData({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="wrapper">
      <section className="touch">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="block-text center">
                <h6 className="sub-heading">
                  <span>{t("contactSubheading")}</span>
                </h6>
                <h3 className="heading">
                  {t("contactHeading1")} <br />
                  {t("contactHeading2")}
                </h3>
              </div>
              <div className="touch__main">
                <div className="info">
                  <h5>{t("contactInfoTitle")}</h5>
                  <ul className="list">
                    <li>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 7C10 7.53043 9.78929 8.03914 9.41421 8.41421C9.03914 8.78929 8.53043 9 8 9C7.46957 9 6.96086 8.78929 6.58579 8.41421C6.21071 8.03914 6 7.53043 6 7C6 6.46957 6.21071 5.96086 6.58579 5.58579C6.96086 5.21071 7.46957 5 8 5C8.53043 5 9.03914 5.21071 9.41421 5.58579C9.78929 5.96086 10 6.46957 10 7V7Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13 7C13 11.7613 8 14.5 8 14.5C8 14.5 3 11.7613 3 7C3 5.67392 3.52678 4.40215 4.46447 3.46447C5.40215 2.52678 6.67392 2 8 2C9.32608 2 10.5979 2.52678 11.5355 3.46447C12.4732 4.40215 13 5.67392 13 7V7Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p>{t("contactAddress")}</p>
                    </li>
                    <li>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_794_6441)">
                          <path
                            d="M1.5 4.5C1.5 10.0227 5.97733 14.5 11.5 14.5H13C13.3978 14.5 13.7794 14.342 14.0607 14.0607C14.342 13.7794 14.5 13.3978 14.5 13V12.0853C14.5 11.7413 14.266 11.4413 13.932 11.358L10.9833 10.6207C10.69 10.5473 10.382 10.6573 10.2013 10.8987L9.55467 11.7607C9.36667 12.0113 9.042 12.122 8.748 12.014C7.65659 11.6128 6.66544 10.9791 5.84319 10.1568C5.02094 9.33456 4.38725 8.34341 3.986 7.252C3.878 6.958 3.98867 6.63333 4.23933 6.44533L5.10133 5.79867C5.34333 5.618 5.45267 5.30933 5.37933 5.01667L4.642 2.068C4.60143 1.9058 4.50781 1.7618 4.37604 1.65889C4.24426 1.55598 4.08187 1.50006 3.91467 1.5H3C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V4.5Z"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_794_6441">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <p>{t("contactPhone")}</p>
                    </li>
                    <li>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.5 4.5V11.5C14.5 11.8978 14.342 12.2794 14.0607 12.5607C13.7794 12.842 13.3978 13 13 13H3C2.60218 13 2.22064 12.842 1.93934 12.5607C1.65804 12.2794 1.5 11.8978 1.5 11.5V4.5M14.5 4.5C14.5 4.10218 14.342 3.72064 14.0607 3.43934C13.7794 3.15804 13.3978 3 13 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5M14.5 4.5V4.662C14.5 4.9181 14.4345 5.16994 14.3096 5.39353C14.1848 5.61712 14.0047 5.80502 13.7867 5.93933L8.78667 9.016C8.55014 9.16169 8.2778 9.23883 8 9.23883C7.7222 9.23883 7.44986 9.16169 7.21333 9.016L2.21333 5.94C1.99528 5.80569 1.81525 5.61779 1.69038 5.3942C1.56551 5.1706 1.49997 4.91876 1.5 4.66267V4.5"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p>{t("contactEmail")}</p>
                    </li>
                  </ul>

                  <div className="image">
                    <img src={img} alt={t("contactImageAlt")} />
                  </div>
                </div>

                <form onSubmit={handleSubmit} className="form-box">
                  <div className="row">
                    <div className="col">
                      <label>{t("contactFormName")}</label>
                      <input 
                        type="text" 
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                        aria-label={t("contactFormName")}
                        placeholder={t("contactFormNamePlaceholder")}
                      />
                      {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                    </div>
                    <div className="col">
                      <label>{t("contactFormEmail")}</label>
                      <input 
                        type="email" 
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        aria-label={t("contactFormEmail")}
                        placeholder={t("contactFormEmailPlaceholder")}
                      />
                      {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label>{t("contactFormPhone")}</label>
                      <input 
                        type="tel" 
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="form-control"
                        aria-label={t("contactFormPhone")}
                        placeholder={t("contactFormPhonePlaceholder")}
                      />
                    </div>
                    <div className="col">
                      <label>{t("contactFormSubject")}</label>
                      <select 
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className={`form-control ${errors.subject ? 'is-invalid' : ''}`}
                        aria-label={t("contactFormSubject")}
                      >
                        <option value="">{t("contactFormSelectDefault")}</option>
                        <option value="support">{t("contactFormSelectSupport")}</option>
                        <option value="business">{t("contactFormSelectBusiness")}</option>
                        <option value="other">{t("contactFormSelectOther")}</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label>{t("contactFormMessage")}</label>
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                        cols="30"
                        rows="10"
                        aria-label={t("contactFormMessage")}
                        placeholder={t("contactFormMessagePlaceholder")}
                      ></textarea>
                      {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                    </div>
                  </div>
                  <div className="row mb-0">
                    <div className="col">
                      <button 
                        type="submit" 
                        className={`action-btn ${isSubmitting ? 'loading' : ''}`}
                        disabled={isSubmitting}
                      >
                        <span>
                          {isSubmitting ? t("contactFormSubmitting") : t("contactFormSubmit")}
                        </span>
                      </button>
                      {submitStatus === 'success' && (
                        <div className="alert alert-success mt-3" role="alert">
                          {t("contactFormSuccess")}
                        </div>
                      )}
                      {submitStatus === 'error' && (
                        <div className="alert alert-danger mt-3" role="alert">
                          {t("contactFormError")}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="map">
        <div className="container">
          <div className="row">
            <div className="map__main">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2799.7147834186247!2d-75.49893492346277!3d45.44661623715786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce0f0c7c3a3d3d%3A0x4c1c5f0c7c3a3d3d!2s2020%20Lanthier%20Dr%20%239%2C%20Suite%20%23236%2C%20Ottawa%2C%20ON%20K4A%203V4%2C%20Canada!5e0!3m2!1sen!2sca!4v1707414234567!5m2!1sen!2sca"
                title={t("contactMapTitle")}
                height="600"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              />
            </div>
          </div>
        </div>
      </div>

      <Faq3 data={dataFaqs} />
      <Footer />
    </div>
  );
}

export default Contact;
