import React, { useState, useEffect } from "react";
import img1 from "../../assets/images/layouts/banner.png";
import img2 from "../../assets/images/icon/icon-01.png";
import img3 from "../../assets/images/layouts/avt-01.png";
import { useTranslation } from "../../hooks/useTranslation";
import './styles.scss';

function Banner(props) {
  const { t } = useTranslation();
  const [adaPrice, setAdaPrice] = useState(null);
  const [priceChange, setPriceChange] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAdaPrice = async () => {
      try {
        const response = await fetch(
          'https://api.coingecko.com/api/v3/simple/price?ids=cardano&vs_currencies=usd,eur'
        );
        const data = await response.json();
        
        if (adaPrice) {
          const change = ((data.cardano.usd - adaPrice.usd) / adaPrice.usd) * 100;
          setPriceChange(change);
        } else {
          setPriceChange(0);
        }
        
        setAdaPrice(data.cardano);
      } catch (error) {
        console.error('Error ADA price:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAdaPrice();
    // Update price every 5 minutes
    const interval = setInterval(fetchAdaPrice, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [adaPrice]);

  return (
    <section className="banner flex flex-col flex-grow">
      <div className="shape right"></div>
      <div className="container big">
        <div className="row">
          <div className="col-xl-6 col-md-12">
            <div className="banner__left">
              <div className="block-text">
                <h2 className="heading">
                  {" "}
                  {t("bannerTitle1")} <br />
                  {t("bannerTitle2")}{" "}
                  <span className="s1 arlo_tm_animation_text_word">
                    {t("bannerTitle3")}
                  </span>{" "}
                  <br />
                  {t("bannerTitle4")}
                </h2>
                <p className="desc">
                  {t("bannerDescription")}
                </p>

                <a href="https://dapp.ekival.com/" className="action-btn">
                  <span>{t("launchApp")}</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="banner__right">
              <div className="image">
                <img src={img1} alt="cyfonii" />
              </div>

              <div className="price price-card">
                <div className="icon">
                  <img src={img2} alt="ADA" />
                </div>
                <div className="content">
                  <p className="price-label">{t("currentBid")}</p>
                  {isLoading ? (
                    <div className="loading-price">
                      <div className="loading-spinner"></div>
                      <span>{t("loading")}</span>
                    </div>
                  ) : adaPrice ? (
                    <div className="ada-price">
                      <h5 className="crypto-name">
                        <span className="crypto-icon">₳</span>
                        ADA
                      </h5>
                      <div className="price-details">
                        <div className="price-row">
                          <span className="currency-label">USD</span>
                          <div className="price-info">
                            <p className="price-value">
                              <span className="currency-symbol">$</span>
                              <span className="amount">{adaPrice.usd.toFixed(2)}</span>
                            </p>
                            {priceChange !== null && (
                              <div className={`trend-indicator ${priceChange >= 0 ? 'up' : 'down'}`}>
                                <span className="trend-arrow">{priceChange >= 0 ? '↑' : '↓'}</span>
                                <span className="trend-value">{Math.abs(priceChange).toFixed(2)}%</span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="price-row">
                          <span className="currency-label">EUR</span>
                          <div className="price-info">
                            <p className="price-value">
                              <span className="currency-symbol">€</span>
                              <span className="amount">{adaPrice.eur.toFixed(2)}</span>
                            </p>
                            {priceChange !== null && (
                              <div className={`trend-indicator ${priceChange >= 0 ? 'up' : 'down'}`}>
                                <span className="trend-arrow">{priceChange >= 0 ? '↑' : '↓'}</span>
                                <span className="trend-value">{Math.abs(priceChange).toFixed(2)}%</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="error-price">
                      <span className="error-icon">⚠️</span>
                      <span>{t("error")}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="owner">
                <div className="image">
                  <img src={img3} alt={t("mobileMoneyAlt")} />
                </div>
                <div className="content">
                  <h5>{t("mobileMoneyTitle")}</h5>
                  <p>{t("mobileMoneySubtitle")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
